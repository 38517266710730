export default [
    {
        path: "/incidentes/listar",
        name: "web.incidentes.listar",
        component: () => import("@/views/incidentes/IncidentesListar.vue"),
        meta: {
            pageTitle: "Incidentes",
            breadcrumb: [
                {
                    text: "Incidentes",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/incidentes/det/listar",
        name: "web.incidentes.det.listar",
        component: () =>
            import("@/views/incidentes/IncidentesDetalleListar.vue"),
        meta: {
            pageTitle: "Incidentes Detalle",
            breadcrumb: [
                {
                    text: "Incidentes Detalle",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },

    {
        path: "/incidentes/troubleshooting",
        name: "web.incidentes.troubleshooting",
        component: () =>
            import("@/views/incidentes/IncidentesTroubleshooting.vue"),
        meta: {
            pageTitle: "Incidentes Troubleshooting",
            breadcrumb: [
                {
                    text: "Incidentes Troubleshooting",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
];
