export default [
    {
        path: '/itemsGrupos/',
        name: 'web.itemsGrupos.listar',
        component: () => import('@/views/itemsGrupos/ItemsGruposListar.vue'),
        meta: {
            pageTitle: 'Items Grupos',
            breadcrumb: [
                {
                    text: 'Items Grupos',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsSeries/',
        name: 'web.itemsSeries.listar',
        component: () => import('@/views/itemsSeries/ItemsSeriesListar.vue'),
        meta: {
            pageTitle: 'Items Series',
            breadcrumb: [
                {
                    text: 'Items Series',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsSubGrupos/:idItemSubGrupo?',
        name: 'web.itemsSubGrupos.listar',
        component: () => import('@/views/itemsSubGrupos/ItemsSubGruposListar.vue'),
        meta: {
            pageTitle: 'Items Sub Grupos',
            breadcrumb: [
                {
                    text: 'Items Sub Grupos',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/items/busqueda/:idItem?',
        name: 'web.items.busqueda',
        component: () => import('@/views/items/pantallaBusquedaItem.vue'),
        meta: {
            pageTitle: 'Busqueda de Item',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Busqueda de Item',
                    active: true,
                },
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/items/',
        name: 'web.items.listar',
        component: () => import('@/views/items/ItemsListar.vue'),
        meta: {
            pageTitle: 'Items',
            breadcrumb: [
                {
                    text: 'Items',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsPreciosBusqueda/',
        name: 'web.itemsPrecios.busqueda',
        component: () => import('@/views/itemsPrecios/pantallaBusquedaItemPrecio.vue'),
        meta: {
            pageTitle: 'Busqueda Item Precio',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Busqueda Items Precios',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/itemsPrecios/',
        name: 'web.itemsPrecios.listar',
        component: () => import('@/views/itemsPrecios/ItemsPreciosListar.vue'),
        meta: {
            pageTitle: 'Items Precios',
            breadcrumb: [
                {
                    text: 'Items Precios',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/manufacturadores/',
        name: 'web.manufacturadores.listar',
        component: () => import('@/views/manufacturadores/ManufacturadoresListar.vue'),
        meta: {
            pageTitle: 'Manufacturadores',
            breadcrumb: [
                {
                    text: 'Manufacturadores',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/preciosListas/',
        name: 'web.preciosListas.listar',
        component: () => import('@/views/preciosListas/PreciosListaListar.vue'),
        meta: {
            pageTitle: 'Precios Lista',
            breadcrumb: [
                {
                    text: 'Precios Lista',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudes/supervisor/',
        name: 'web.transferenciasSolicitudes.supervisor.listar',
        component: () => import('@/views/transferenciasSolicitudes/TrasnferenciasSolicitudesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes',
            breadcrumb: [
                {
                    text: 'Transferencias Solicitudes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudes/',
        name: 'web.transferenciasSolicitudes.listar',
        component: () => import('@/views/transferenciasSolicitudes/TrasnferenciasSolicitudesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItems/add/',
        name: 'web.transferenciasSolicitudesItems.add',
        component: () => import('@/views/transferenciasSolicitudesItems/transferenciasSolicitudesItemsAgregar.vue'),
        meta: {
            pageTitle: 'Agregar Item a Transferencia',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Agregar Item',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItems/',
        name: 'web.transferenciasSolicitudesItems.listar',
        component: () => import('@/views/transferenciasSolicitudesItems/transferenciasSolicitudesItemsListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItems/verFinalizado',
        name: 'web.transferenciasSolicitudesItems.verFinalizado',
        component: () => import('@/views/transferenciasSolicitudesItems/transferenciasSolicitudesItemsListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItemsLotes/',
        name: 'web.transferenciasSolicitudesItemsLotes.listar',
        component: () => import('@/views/transferenciasSolicitudesItemsLotes/transferenciasSolicitudesItemsLotesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items Lotes',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items Lotes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItemsSeries/',
        name: 'web.transferenciasSolicitudesItemsSeries.listar',
        component: () => import('@/views/transferenciasSolicitudesItemsSeries/transferenciasSolicitudesItemsSeriesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items Series',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items Series',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItemsLotes/verFinalizado',
        name: 'web.transferenciasSolicitudesItemsLotes.verFinalizado',
        component: () => import('@/views/transferenciasSolicitudesItemsLotes/transferenciasSolicitudesItemsLotesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items Lotes',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items Lotes',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/transferenciasSolicitudesItemsSeries/verFinalizado',
        name: 'web.transferenciasSolicitudesItemsSeries.verFinalizado',
        component: () => import('@/views/transferenciasSolicitudesItemsSeries/transferenciasSolicitudesItemsSeriesListar.vue'),
        meta: {
            pageTitle: 'Transferencias Solicitudes Items Series',
            breadcrumb: [
                {
                    text: 'Almacenes de Ventas',
                    to: { name: 'web.auth.almacenes.listar' }
                },
                {
                    text: 'Transferencias Solicitudes Items Series',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/preciosEspeciales/',
        name: 'web.itemsPreciosEspeciales.listar',
        component: () => import('@/views/itemsPreciosEspeciales/ItemsPreciosEspecialesListar.vue'),
        meta: {
            pageTitle: 'Items Precios Especiales',
            breadcrumb: [
                {
                    text: 'Items Precios Especiales',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/preciosEspecialesTemporada/',
        name: 'web.itemsPreciosTemporadas.listar',
        component: () => import('@/views/itemsPreciosEspeciales/ItemsPreciosEspecialesTemporadasListar.vue'),
        meta: {
            pageTitle: 'Items Precios Especiales Temporada',
            breadcrumb: [
                {
                    text: 'Items Precios Especiales',
                    to: { name: 'web.itemsPreciosEspeciales.listar' }
                },
                {
                    text: 'Items Precios Especiales Temporada',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/preciosEspecialesVolumen/',
        name: 'web.itemsPreciosVolumen.listar',
        component: () => import('@/views/itemsPreciosEspeciales/ItemsPreciosEspecialesVolumenListar.vue'),
        meta: {
            pageTitle: 'Items Precios Especiales Temporada',
            breadcrumb: [
                {
                    text: 'Items Precios Especiales',
                    to: { name: 'web.itemsPreciosEspeciales.listar' }
                },
                {
                    text: 'Items Precios Especiales Volumen',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/gruposDescuentos/',
        name: 'web.gruposDescuentos.listar',
        component: () => import('@/views/gruposDescuentos/GruposDescuentosListar.vue'),
        meta: {
            pageTitle: 'Grupos Descuentos',
            breadcrumb: [
                {
                    text: 'Grupos Descuentos',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: '/gruposDescuentosDet/',
        name: 'web.gruposDescuentosDet.listar',
        component: () => import('@/views/gruposDescuentos/GruposDescuentosDetalleListar.vue'),
        meta: {
            pageTitle: 'Grupos Descuentos Detalle',
            breadcrumb: [
                {
                    text: 'Grupos Descuentos',
                    to: { name: 'web.gruposDescuentos.listar' }
                },
                {
                    text: 'Grupos Descuentos Detalle',
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
]

