export default [
    {
        path: "/giftcards/listar",
        name: "web.giftcards.listar",
        component: () => import("@/views/giftcards/GiftCardsListar.vue"),
        meta: {
            pageTitle: "GiftCards",
            breadcrumb: [
                {
                    text: "GiftCards",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
    {
        path: "/giftcards/regalos",
        name: "web.giftcards.regalos",
        component: () => import("@/views/giftcards/GiftCardsRegalos.vue"),
        meta: {
            pageTitle: "GiftCards Regalos",
            breadcrumb: [
                {
                    text: "GiftCards",
                    active: true,
                },
            ],
            requiresAuth: true,
            permissionRequired: true,
        },
    },
];
